.printView{
    width: 35%;
    padding: 4px;
    /* border: 1px solid black; */
   
}
.rps_logo{
    width: 70%;
    margin: auto;
    /* border: 1px solid black; */
    text-align: center;
}
.bill_no{
    text-align: center;
}
.Details{
   text-align: center;
}
.order_table{
    width: 100%;
    text-align: center;
}

thead tr
{
    border-top:1px solid black ;
    border-bottom:1px solid black ;
}
thead tr th{
    font-weight: bolder;
    font-size: 11px;
}
tbody tr{
    /* background-color: black; */
    width: 100%;
}
.itemsname{
    width: 120px;
}
.fnt {
    width: 100px;
    font-size: 12px;
    font-weight: bolder;
}