.data-table{
  font-family: 'Helvetica';
}
.data-table thead{
  /* color: '#e8e9eb'; */
  font-size: 12px;
  font-weight: bold;
}
.data-table tbody{
  font-size: 12px;
}
