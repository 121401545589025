.ceo-dashboard-parent-box {
    width: 100%;
}

.ceo-dashboard-parent-box .ceo-dashboard-child-box {
    margin: 0.6em 0em 2em 0em;
    padding: 1.6em 0;
    width: 100%;
    font-size: 12px;
    font-weight: bold;
    background-color: white;
    color: #515151;
    border: 1px solid #c7c7c7;
    border-radius: 10px;
    box-shadow: 0 0 2px #c7c7c7;
}
